// Свой код jquery
$(document).ready(function () {
    // функция проверки наличия атрибута
    $.fn.hasAttr = function (name) {
        return this.attr(name) !== undefined;
    };


    // Подключаемые функции
    //svg4everybody(); // SVG for Everybody
    lazyload(); // отложенная загрузка
    //range(); // slider цен
    select(); // выпадающие списки
    sticker(); // Стикеры +2 в item
    Fancybox.bind("[data-fancybox]", {}); // Fancybox


    // Размеры адаптивки
    var xs = 359,
        sm = 575,
        md = 767,
        lg = 1023,
        xl = 1279,
        xxl = 1439,
        vw575 = 575,
        vw1599 = 1599;


    // id метрики
    var idmetrika = $('.footer__about').data('number');


    // отложенная загрузка
    function lazyload() {
        $('.lazyload').Lazy({
            visibleOnly: true,
            effect: 'fadeIn',
        });
        $('.lazyload img').Lazy({
            visibleOnly: true,
            effect: 'fadeIn',
        });
    };



    // выпадающие списки в фильтре
    function select() {
        $('.form-select').each(function () {
            var current = '#' + $(this).attr('id');

            // Показать скрыть выпадающий список
            $(this).on('click', '.form-select__arrow', function () {
                if ($(current).hasClass('active')) {
                    $(current + ' .form-select__option').slideUp(200);
                    $(current).removeClass('active');
                } else {
                    $('.form-select').removeClass('active');
                    $('.form-select__option').slideUp(200);
                    $(current + ' .form-select__option').slideDown(200);
                    $(current).addClass('active');
                }
            });
            $(this).on('click', '.form-checkbox', function () {
                current_option_filter();
            });

            // Манипуляции со списком
            function current_option_filter() {
                var count = $(current + ' input:checked').length;
                current_value = $(current).find('.form-select__value');
                if ($(current).hasClass('--count')) {
                    // тип 3 - +5 - дописать класс --count
                    if (count > 0) {
                        current_value.html('<span>+' + count + '</span>');
                    } else {
                        current_value.text('Не важно');
                    }
                } else {
                    value_val = [];
                    $(current + ' input:checked').each(function () {
                        value_val.push($(this).val().split("=="));
                        if (value_val.length > 0) {
                            var count_arr = count - 1;
                            if ($(current).hasClass('--list')) {
                                // тип 2 - один, два, три 
                                current_value.html('<b>' + value_val.join(", ") + '</b>');
                            } else {
                                // по умолчанию
                                // тип 1 - Выбранный элемент +2 - по умолчанию
                                if (count_arr > 0) {
                                    current_value.html('<b>' + value_val[0] + '</b><span>+' + count_arr + '</span>');
                                } else {
                                    current_value.html('<b>' + value_val[0] + '</b>');
                                }
                            }
                        }
                    });
                    if (value_val.length == 0) {
                        current_value.text('Не важно');
                    }
                }

                // Снимаем все галочки если стоят
                if (count > 0) {
                    $(current + ' .null-checkbox').removeClass('disabled');
                    $(current).on('click', '.null-checkbox', function () {
                        $(current + ' input:checked').prop('checked', false);
                        current_value.text('Не важно');
                        $(current + ' .form-select__option').slideUp(200);
                        $(current).removeClass('active');
                        $(current + ' .null-checkbox').addClass('disabled');
                    });
                } else {
                    $(current + ' .null-checkbox').addClass('disabled');
                }

            };
            current_option_filter();

            // Закрываем при клике мимо
            jQuery(function ($) {
                $(document).mouseup(function (e) {
                    var div = $(".form-select");
                    if (!div.is(e.target) && div.has(e.target).length === 0) {
                        $(div).find('.form-select__option').slideUp(200);
                        $(div).removeClass('active');
                    }
                });
            });

        });
    };


    // Только один отмеченный чекбокс
    $('.--one input:checkbox').click(function () {
        if ($(this).is(':checked')) {
            $('.--one input:checkbox').not(this).prop('checked', false);
        }
    });

    // Ссылка в выпадающем списке
    $('.form-select').on('click', '.form-link', function () {
        var search = window.location.search,
            href = $(this).attr('href');
        document.location.href = href + search;
        //console.log(href + search);
        return false;
    });





    // Скрыть стикеты
    function sticker() {
        $('.stick-count').each(function () {
            var show = 2,
                sticker = $(this).children('.sticker');

            if (sticker.length > show) {
                var count = sticker.length - show;
                sticker.slice(show).addClass('hide');
                $('<li class="sticker view-sticker">+' + count + '</li>').prependTo(this);

                $(this).children('.hide').wrapAll(function () {
                    if (sticker.hasClass('hide'))
                        return "<div class='sticker__more'></div>";
                });
            }
        });
    };


    // Ресайз
    $(window).on("resize", function () {
        //console.log($('body').innerWidth());
    });

    // Показать скрыть пункты фильтра
    function more_filter() {
        $('.mfilter-block').each(function () {
            var cur = $(this);

            if ($('body').innerWidth() + 17 > xxl) {
                var show = 4;
            } else if ($('body').innerWidth() + 17 > xl) {
                var show = 3;
            } else if ($('body').innerWidth() + 17 > lg) {
                var show = 2;
            }

            var sticker = $(this).children('.col'),
                shows = sticker.length - show;

            if (sticker.length > show) {
                sticker.slice(show).addClass('hide');
            }

            $(this).children('.hide').wrapAll(function () {
                if (sticker.hasClass('hide'))
                    return '<div class="filter_hide grid grid-4 xl-3 lg-2 sm-1"></div>';
            });

            var current = $(this).find('.filter_hide');
            $('.filter-block').on('click', '.filter-more', function () {
                $('.filter-block').toggleClass('show');
                //$(current).find('.filter_hide').slideToggle(300).toggleClass('active');
                if (current.hasClass('show')) {
                    $(current).css('display', 'flex').slideUp(300).removeClass('show');
                    $(this).removeClass('active').find('span').text('Расширенный фильтр');
                } else {
                    $(current).css('display', 'flex').hide().slideDown(300).addClass('show');
                    $(this).addClass('active').find('span').text('Скрыть фильтр');
                }
            });
        });
    };
    more_filter();



    // показать скрыть фильтр
    $('body').on('click', '.view-filter', function () {
        var cur_id = '#' + $(this).data('id');
        //$('body').addClass('lock');
        $(cur_id).css('display', 'flex').hide().fadeIn(300).addClass('show');
        return false;
    });
    $('.popup-filter').on('click', '.popup-filter-close, .filter-close, .popup-close--2', function () {
        var cur_id = '#' + $(this).closest('.popup-filter').attr('id');
        //$('body').removeClass('lock');
        $(cur_id).removeClass('show');
        setTimeout(function () {
            $(cur_id).fadeOut(300);
        }, 250);
    });



    // показать скрыть попап
    $('body').on('click', '.popup-view', function () {
        var current = '#' + $(this).data('id');

        if ($(this).hasAttr('data-title')) {
            $(current + ' .form-hide .popup-form__title').html($(this).data('title'));
        };
        if ($(this).hasAttr('data-desc')) {
            $(current + ' .form-hide .popup-form__desc').html($(this).data('desc'));
        }
        $(current + ' .form-metka').val($(this).data('metka'));
        $(current + ' .form-button').text($(this).data('button'));

        $(current).css('display', 'flex').hide().fadeIn(300).addClass('show');

        var metrika = $(this).data('target');
        ym(idmetrika, 'reachGoal', metrika);
        return false;
    });

    $('.popup').on('click', '.popup-close, .filter-close, .popup-close--2', function () {
        var current = '#' + $(this).closest('.popup').attr('id');
        //$('body').removeClass('lock');
        $(current).removeClass('show');
        setTimeout(function () {
            $(current).fadeOut(300);
        }, 250);
    });

    // Отправка формы
    $('.form-js').each(function () {
        var cur_form = '#' + $(this).attr('id');
        $(this).on('click', '.form-button', function () {
            document.addEventListener('fetchit:success', (e) => {
                const { form, response } = e.detail;
                form.style.display = 'none'; // Скрываем форму!

                $(cur_form).closest('.form-hide').hide();
                $(cur_form).closest('.form-hide').siblings('.form-ok').fadeIn(100);

                var target = $(cur_form).data('target');
                ym(idmetrika, 'reachGoal', target);
                ym(idmetrika, 'reachGoal', 'all_orders');

                // проверка на повторную заявку
                if (!Cookies.get('double_order_cookie') == true) {
                    Cookies.set('double_order_cookie', true, { expires: 10, path: '/' });

                    ym(idmetrika, 'reachGoal', 'order');
                    dataLayer.push({ 'event': target });
                } else {
                    ym(idmetrika, 'reachGoal', 'double_orders');
                    //console.log('повтораня заявка');
                }

            });
        });
        // Антиспам - добавление обязательного поля
        $(this).append('<input type="text" name="org" value="" class="validate-input" />');
    });




    // Меню показать ЕЩЕ
    $('.more-menu').moreNav();

    // показать скрыть меню
    $('body').on('click', '.view-menu', function () {
        $('.header-top').toggleClass('show');
        return false;
    });


    // Закрыть окна клавишей Escape
    $(document).keyup(function (e) {
        if (e.key === "Escape" || e.keyCode === 27) {
            //console.log('Нажата клавиша Escape');
            $('.popup').removeClass('show');
            setTimeout(function () {
                $('.popup').fadeOut(300);
            }, 250);
            $('.popup-maps').fadeOut(300);
        }
    });


    // Слайдер в шапке
    $('.slider-header').each(function () {
        var id = $(this).data('id'),
            current = '.slider-header[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            mousewheelControl: true,
            slidesPerView: 1,
            lazy: true,
            freemode: true,
            speed: 1000,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            effect: "creative",
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: ["-25%", 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
        });
    });


    // Слайдер в шапке фото
    $('.slider-header-2').each(function () {
        var id = $(this).data('id'),
            current = '.slider-header-2[data-id="' + id + '"]';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            slidesPerView: 1,
            lazy: true,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 6000,
            },
            effect: "fade",
        });
    });


    // Слайдер в шапке
    $('.slider-about').each(function () {
        var id = $(this).data('id'),
            current = '.slider-about[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            mousewheelControl: true,
            slidesPerView: 1,
            freemode: true,
            speed: 600,
            direction: "vertical",
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
        });
    });


    // Слайдер на плане этажа
    $('.slider-level').each(function () {
        var id = $(this).data('id'),
            current = '.slider-level[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            slidesPerView: 3,
            //centeredSlides: true,
            direction: "vertical",
            freemode: true,
            speed: 600,
            /*navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },*/
            /*breakpoints: {
                0: {
                    slidesPerView: 1,
                    allowTouchMove: true,
                },
                767: {
                    slidesPerView: 3,
                    allowTouchMove: true,
                },
            },*/
        });
    });


    // Слайдер акций
    $('.slider-sales').each(function () {
        var id = $(this).data('id'),
            current = '.slider-sales[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 16,
            slidesPerView: 4,
            freemode: true,
            allowTouchMove: true,
            speed: 600,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                767: {
                    slidesPerView: 2,
                },
                1279: {
                    slidesPerView: 3,
                },
                1599: {
                    slidesPerView: 4,
                },
            },
        });
    });



    // Табы
    function tabs(e) {
        $(e).on("click", function () {
            var tabs = $(this).closest('.tabs-init'),
                current = $(this).data('id');
            tabs.find('.btn-tab').removeClass('active');
            $(this).addClass('active');
            tabs.find('.tab').hide().removeClass('active');
            tabs.find('.tab-' + current).fadeIn('300').addClass('active');
        });
    };
    tabs('.btn-tab');

    // Табы при наведении
    function tabs_hover(e) {
        $(e).hover(function () {
            var tabs = $(this).closest('.tabs-init'),
                current = $(this).data('id');
            tabs.find('.btn-tab').removeClass('active');
            $(this).addClass('active');
            tabs.find('.tab').hide().removeClass('active');
            tabs.find('.tab-' + current).fadeIn('300').addClass('active');
        });
    };
    tabs_hover('.tabs-hover .btn-tab');


    // Подробности у ремонта
    function remont() {
        $('.remont-detail__btn').on("click", function () {
            var current = $(this).parent('.remont-detail');
            if (!current.hasClass('active')) {
                $('.remont-detail').removeClass('active');
                current.addClass('active');
            } else {
                current.removeClass('active');
            }

        });
    };
    remont();


    // Слайдер в особенностей
    $('.slider-properties').each(function () {
        var id = $(this).data('id'),
            current = '.slider-properties[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            mousewheelControl: true,
            slidesPerView: 1,
            freemode: true,
            speed: 1000,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            effect: "creative",
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: ["-20%", 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
        });
    });


    // Слайдер в особенностей 2
    $('.slider-properties-2').each(function () {
        var id = $(this).data('id'),
            current = '.slider-properties-2[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';

        var swiper_2 = new Swiper(current + ' .swiper-dependent', {
            spaceBetween: 0,
            slidesPerView: 1,
            freeMode: true,
            speed: 700,
            effect: "fade",
            allowTouchMove: false,
            parallax: true,
            breakpoints: {
                0: {
                    autoHeight: true,
                },
                1023: {
                    autoHeight: false,
                }
            },
        });
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            mousewheelControl: true,
            slidesPerView: 1,
            freeMode: true,
            speed: 700,
            lazy: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            effect: "creative",
            creativeEffect: {
                prev: {
                    shadow: false,
                    translate: ["-25%", 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
            thumbs: {
                swiper: swiper_2,
            },
        });

    });



    // Кнопки инфраструктуры из swiper
    $('.slider-maps').each(function () {
        var id = $(this).data('id'),
            current = '.slider-maps[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: "auto",
            spaceBetween: 8,
            freemode: true,
            mousewheelControl: true,
            keyboardControl: true,
            centeredSlidesBounds: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
        });
    });



    // Слайдер расположения swiper
    $('.slider-mood').each(function () {
        var id = $(this).data('id'),
            current = '.slider-mood[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: 3,
            lazy: true,
            spaceBetween: 16,
            freemode: true,
            mousewheelControl: true,
            keyboardControl: true,
            speed: 1000,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    allowTouchMove: true,
                },
                767: {
                    slidesPerView: 2,
                    allowTouchMove: true,
                },
                1023: {
                    slidesPerView: 1,
                    allowTouchMove: true,

                },
                1279: {
                    slidesPerView: 3,
                    allowTouchMove: false,
                },
            },
        });
        if ($(current + " .swiper-slide").length < 3) {
            $(current + " .swiper-control--wrap").hide();
        };
        // beforeTransitionStart более
        swiper.on('slideChange', function () {
            if (swiper.realIndex == '0') {
                $('.mood__maps').removeClass('change');

            } else {
                $('.mood__maps').addClass('change');
            }

            lazyload();
        })
        swiper.on('reachBeginning', function () {
            $('.mood__maps').removeClass('change');
        });
    });


    // Слайдер ход строительства
    $('.slider-progress').each(function () {
        var id = $(this).data('id'),
            current = '.slider-progress[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: 4,
            spaceBetween: 16,
            freemode: true,
            lazy: true,
            mousewheelControl: true,
            keyboardControl: true,
            speed: 1000,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                },
                1023: {
                    slidesPerView: 3,
                },
                1279: {
                    slidesPerView: 4,
                },
            },
        });
    });

    // Посчитаем кол-во фото в месяце
    $('.progress-item--wrap').each(function () {
        count = $(this).find('.progress-item__photo').length;
        $(this).find('.progress-count').text(count + 1);

        if (count < 2) {
            $(this).find('.progress-item__count').hide();
        }
    });


    // Галерея 
    $('.slider-gallery').each(function () {
        var id = $(this).data('id'),
            current = '.slider-gallery[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: 1,
            lazy: true,
            breakpointsBase: 'container',
            spaceBetween: 0,
            freemode: true,
            mousewheelControl: true,
            keyboardControl: true,
            speed: 1000,
            grabCursor: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            effect: "creative",
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: ["-20%", 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
        });
        if ($(current + " .swiper-slide").length < 1) {
            $(current + " .swiper-control--wrap").hide();
        };
    });


    // Шахматка
    $('.scrollbar-chess').each(function () {
        var id = $(this).data('id'),
            current = '.scrollbar-chess[data-id="' + id + '"]',
            scrollbar = current + ' .swiper-scrollbar';
        var swiper = new Swiper(current + ' .swiper', {
            direction: "horizontal",
            slidesPerView: "auto",
            freeMode: true,
            mousewheelControl: true,
            scrollbar: {
                el: scrollbar,
                draggable: true,
                //snapOnRelease: true,
                //hide: true,
            },
            mousewheel: true,
        });
        swiper.mousewheel.disable();
    });


    // Показать пин на шахматке если он есть
    function status_chess() {
        $('.chess-item').each(function () {
            var item = $(this).data('i'),
                id = $('#' + item).attr('id'),
                id_status = $('#' + item).attr('data-status');

            //console.log(item + ' = ' + id  + ' = ' + id_status);			
            if (id != undefined) {
                $(this).removeClass('--sold');

                switch (id_status) {
                    case 'sale':
                        $(this).addClass('--dot');
                        //item.addClass('--sale');
                        console.log("--dot");
                        break;
                    case 'booked':
                        $(this).addClass('--lock');
                        console.log("--booked");
                        break;
                }
            } else {
                $(this).removeClass('--dot --lock --sale').addClass('--sold');
            }
        });
    };
    status_chess();








    // Инфраструктура
    // Подгрузка скриптка карты и инициализация
    function load_api() {
        $('.maps-block').each(function () {
            setTimeout(function () {
                $.getScript("https://api-maps.yandex.ru/2.1/?apikey=285a4384-a3e2-4f92-ac29-c1ca26cf5083&lang=ru_RU", function () {

                    ymaps.ready(yandexmaps);
                });
            }, 2200);
        });
    };
    load_api();





    // Только один отмеченный чекбокс с городом
    $('.group-city').on('click', '.input-checkbox', function () {
        var maps_center = JSON.parse('[' + $(this).data('maps-center') + ']');
        //myMap.panTo(maps_center);
        //$('.object-maps--link').attr({'data-coords-center': maps_center, 'data-link': maps_link});
        $('.object-maps--link').attr('data-coords-center', maps_center);
    });




    // определение города
    function city_init() {
        ymaps.geolocation.get({ provider: "yandex" })
            .then(function (res) {
                var g = res.geoObjects.get(0);
                $(".form-city").val(g.getCountry() + ', ' + g.getAdministrativeAreas()[0] + ', ' + g.getLocalities()[0]);
            })
            .catch(function (err) {
                $(".form-city").val('Не удалось установить местоположение', err);
            });
    };
    // Опредление гео при клике на телефон
    $('input[name="phone"]').on("click", function () {
        ymaps.ready(city_init);
    });


    // яндекс карта
    function yandexmaps() {
        $('.maps-block').each(function () {
            var current = $(this).find('.maps').attr('id'),
                coords = JSON.parse('[' + $(this).data('coords-center') + ']'),
                name = $(this).data('name'),
                link_json = $(this).data('link'),
                maps_json = $('.get_json_maps').text(),
                zoom = $(this).data('zoom'),
                clusterize = $(this).data('clusterize'),
                pin = $(this).data('pin');

            var myMap = new ymaps.Map(current, {
                center: coords,
                zoom: zoom,
                controls: []
            }, {
                geolocationControlFloat: 'right',
                zoomControlSize: 'small',
                searchControlProvider: 'yandex#map',
                //autoFitToViewport: 'always', // автоцентровка.. но прыгает
            });

            //myMap.container.fitToViewport(); // автоцентровка..

            myMap.controls.add(
                new ymaps.control.ZoomControl({
                    options: { position: { right: 0 } }
                }),
                new ymaps.control.SearchControl({
                    noPlacemark: true
                })
            );
            //myMap.behaviors.disable( [ 'scrollZoom', 'drag', 'rightMouseButtonMagnifier' ] ); // Удалить все элементы управления
            myMap.behaviors.disable('scrollZoom'); // открычить увеличение скролом
            //myMap.controls.remove('routeEditor'); // скрыть какое нибудь поле управления
            //myMap.behaviors.disable('drag'); // отключить перетаскивание


            // Главный пин
            if (coords != '' || coords != 'undefined') {
                // создание своего пина.. можно дописать параметры типа $[properties.class]
                var houseLayout = ymaps.templateLayoutFactory.createClass('<div class="pin-container"><div class="pin-house"><img src="$[properties.img]" alt=""><i></i></div></div>');

                var housePlacemark = new ymaps.Placemark(coords, {
                    hintContent: '<div class="maps-hint">' + name + '</div>',
                    img: pin,
                    class: '', // получаем эти параметры
                    balloonContent: '<div class="maps-balloon">' + name + '</div>',
                    //hintContent: 'Собственный значок метки',
                    openBalloonOnClick: true, // не открывать балун да/нет
                    balloonPanelMaxMapArea: 0, // В данном примере балун никогда не будет открываться в режиме панели.
                }, {
                    iconLayout: houseLayout,
                    zIndex: 1000,
                    // область наведения
                    iconShape: {
                        type: 'Circle',
                        // Круг описывается в виде центра и радиуса горизонталь, вертикаль
                        coordinates: [-2, -40],
                        radius: 40
                    }
                });
                // Добавляем метку на карту
                myMap.geoObjects.add(housePlacemark);

                housePlacemark.events.add('mouseenter', function (e) {
                    //var target = e.get('target');
                    //target.options.set('iconImageHref', '/assets/img/-1hover.svg');

                    $('.pin-container').addClass('hover');
                });
                housePlacemark.events.add('mouseleave', function (e) {
                    $('.pin-container').removeClass('hover');
                });
            };


            // Объект из параметров блока простой
            /*if (link_json !== '') {
                myMap.geoObjects.add(new ymaps.Placemark(myMap.getCenter(), {
                    balloonContent: '<div class="maps-wrap"><div class="mapsItem__name">' + pin_just + '</div></div>',
                    //hintContent: 'Собственный значок метки',
                    openBalloonOnClick: true, // не открывать балун да/нет
                    balloonPanelMaxMapArea: 0, // В данном примере балун никогда не будет открываться в режиме панели.
                }, {
                    iconLayout: 'default#image',
                    iconImageHref: "/assets/img/svg/pin.svg",
                    iconImageSize: [48, 48],
                    iconImageOffset: [-24, -48],
                    //iconImageHref: pin,
                    //iconImageSize: [96, 96],
                    //iconImageOffset: [-48, -96],
                    zIndex: 1000
                }));
            }*/

            // objectManager - для кластеризации
            /*if (link_json !== '') {
                objectManager = new ymaps.ObjectManager({
                    clusterize: clusterize,
                    gridSize: 20,
                    clusterDisableClickZoom: false, // не увеличивать при клике
                    clusterBalloonContentLayout: "cluster#balloonAccordion", // акардион в балуне
                    clusterBalloonCycling: false,
                    clusterBalloonPanelMaxMapArea: 0, // В данном примере балун никогда не будет открываться в режиме панели.
                    openBalloonOnClick: clusterize, // не открывать балун
                    clusterOpenBalloonOnClick: true, // не открывать балун
                    clusterBalloonAccordionShowIcons: false,  // не открывать иконку в балуне
                    clusterIconLayout: ymaps.templateLayoutFactory.createClass('<div class="clusterIcon">{{ properties.geoObjects.length }}</div>'),
                    clusterIconShape: {
                        type: 'Rectangle',
                        coordinates: [[36, 36], [-18, -36]]
                    },
                });
                objectManager.objects.options.set('preset', {
                    iconImageHref: "/assets/img/svg/pin.svg",
                    iconLayout: "default#image",
                    iconImageSize: [48, 48],
                    iconImageOffset: [-24, -48]
                });
     
                // Подгрузка объектов по ссылке
                myMap.geoObjects.add(objectManager);
                $.ajax({
                    url: link_json
                }).done(function (data) {
                    objectManager.add(data);
                });
            }*/


            // Подгрузка объектов по ссылке
            if (link_json !== '') {
                setTimeout(function () {
                    $.ajax({
                        url: link_json
                    }).done(function (data) {
                        window.infrastructure = ymaps.geoQuery(data).addToMap(myMap);
                    });
                }, 2000);
            }

            // Подгрузка объектов из блока
            if (maps_json.length > 4) {
                window.infrastructure = ymaps.geoQuery(maps_json).addToMap(myMap);
            }


            // Фильтр инфраструктуры
            $('.maps-pin, .all-pin').on('click', function () {
                var shownObjects,
                    check_pin = new ymaps.GeoQueryResult(),
                    all_pin = new ymaps.GeoQueryResult();

                // в цикле собираем все нажатые id
                check_pin = $('.maps-pin').map(function () {
                    if ($(this).children('input').prop('checked')) {
                        var pin = $(this).data('type');
                        return check_pin = infrastructure.search('properties.clusterCaption = "' + pin + '"').add(check_pin);
                    }
                }).get();

                // неведомая хрень.. хз что она делает.. но без нее не работает
                check_pin = infrastructure.search('properties.clusterCaption = ""').add(check_pin);

                // все элементы
                all_pin = infrastructure.search('geometry.type = "Point"');

                // Покажем все пины если нажать все
                if ($('.all-pin').children('input').prop('checked')) {
                    check_pin = infrastructure.search('geometry.type = "Point"').add(check_pin);
                }

                if ($(this).data('all') == '1') {
                    $('.maps-pin').children('input').prop('checked', false);
                } else {
                    $('.all-pin').children('input').prop('checked', false);
                }

                shownObjects = check_pin.intersect(all_pin).addToMap(myMap);
                infrastructure.remove(shownObjects).removeFromMap(myMap);
            });
            // Конец фильтра инфраструктуры





            // Обрабатываем событие открытия балуна на геообъекте
            myMap.events.add('balloonopen', function (e) {
                rank();
                lazyload();
            });



            // Разблокировать перетаскивание карты
            /*
            var toggleLocked = document.querySelector('.locked-maps');
            myMap.behaviors.disable('drag'); // Заблокировано
     
            toggleLocked.addEventListener('click', function () {
                if (myMap.behaviors.isEnabled('drag')) {
                    myMap.behaviors.disable('drag');
                    toggleLocked.querySelector('span').textContent = 'Разблокировать карту';
                } else {
                    myMap.behaviors.enable('drag');
                    toggleLocked.querySelector('span').textContent = 'Заблокировать карту';
                }
            });
     
            // Перемещение по карте
            $('.contact-block').on('click', '.button-group button', function () {
                var coords_center = JSON.parse('[' + $(this).data('coords-center') + ']');
                myMap.panTo(coords_center, {
                    delay: 500
                });
            });
            */


            // центруем карту по добавленным объектам
            //myMap.setBounds(myMap.geoObjects.getBounds()); 

            // Построение маршрута на карте
            //controls: ['routeButtonControl'] // в самое начало
            //var control = myMap.controls.get('routeButtonControl');
            // Зададим координаты пункта отправления с помощью геолокации.
            // control.routePanel.geolocate('from');
            //control.routePanel.state.set({
            //    type: "pedestrian",
            //    to: '51.523986, 46.018446'
            //});
            // Откроем панель для построения маршрутов.
            //control.state.set('expanded', true);

        });
    };




    // Очистить фильтр
    $('.filter-button--clean').on('click', function () {
        var filter = '.form-js-fitter';
        $(filter + ' .form-select__title').text('Не выбрано');
        setTimeout(function () {
            //range_price_filter();
        }, 60);

    });


    // Ипотечный калькулятор
    $('.form-range.--one').each(function () {
        var current = '#' + $(this).attr('id');
        var min = $(this).data('min');
        var max = $(this).data('max');
        var step = $(this).data('step');
        var now = $(current).find('input').val()

        // ползунок стоимости квартиры
        $(current + " .range").slider({
            range: "min",
            value: now,
            min: min,
            max: max,
            step: step,
            slide: function (event, ui) {

                //$(current + " .filter__price").text(ui.value);
                $(current).find('input').val(ui.value);

                var price = $(".calc-price").val(),
                    vznos = $(".calc-vznos").val(),
                    period = $(".calc-period").val();

                if ($(this).hasClass('range--price')) {
                    // Показать какой процент от суммы
                    var vznos_percent = parseFloat(vznos / ui.value * 100).toFixed(0);
                    $('.calc-vznos-percent').each(function () {
                        $(this).text(vznos_percent + '%');
                    });

                    // Максимальный взнос 90% от суммы
                    $('.calc-vznos-max').each(function () {
                        var vznos_max = parseFloat(ui.value * 0.9).toFixed(0),
                            vznos_min = parseFloat(ui.value * 0.15).toFixed(0);
                        $('.range--vznos').slider("option", "max", vznos_max);

                        if (vznos > ui.value) {
                            $('.calc-vznos').val(vznos_min);
                            //$(cur + ' .filter__price').text(vznos_max);
                        } else {
                            $('.calc-vznos').val(vznos);
                        };
                    });
                };

                if ($(this).hasClass('range--vznos')) {
                    // Показать какой процент от суммы
                    var vznos_percent = parseFloat(ui.value / price * 100).toFixed(0);
                    $('.calc-vznos-percent').each(function () {
                        $(this).text(vznos_percent + '%');
                    });
                };

                if ($(this).hasClass('range--srok')) {
                    $('.calc-srok-decl').text(declination(ui.value, [' год', ' года', ' лет']));
                }

                calculator();
                rank();
            },
            //stop: function(event, ui) {}
        });


    });


    // Формула расчета ипотеки
    function calculator() {
        var price = $(".calc-price").val(),
            vznos = $(".calc-vznos").val(),
            period = $(".calc-period").val();

        $('.calc-init').each(function () {
            var current = '#' + $(this).attr('id'),
                rate = $(this).data('procent'),
                period_bank = $(this).data('period'),
                sum = parseFloat(price - vznos).toFixed(0);
            //console.log('сумма кредита ' + sum);
            var i,
                koef,
                result;
            // ставка в месяц
            i = (rate / 12) / 100;
            // коэффициент аннуитета
            koef = (i * (Math.pow(1 + i, period * 12))) / (Math.pow(1 + i, period * 12) - 1);
            // итог
            result = sum * koef;
            //console.log(result.toFixed());
            $(current + ' .price-mount').text(result.toFixed());
            rank();

            if (period > period_bank) {
                $(current).hide();
            } else {
                $(current).show();
            };
        });
    };


    // Показать еще или не еще
    function show_more() {
        $('.show-more').on('click', function () {
            $(this).toggleClass('active');
            $(this).prev().slideToggle();
            return false;
        });
    };
    show_more();


    // разбивка суммы на тысячные
    function rank() {
        document.querySelectorAll('.rank').forEach(function (element) {
            var rank = element.textContent;
            element.textContent = rank.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        });
    }
    rank();

    // разбивка суммы на в input
    function uprank() {
        document.body.addEventListener('input', function (event) {
            if (event.target.classList.contains('uprank')) {
                var rank_val = event.target.value.replace(/[^\0-9\.]/g, '');
                event.target.value = rank_val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
        });
        document.querySelectorAll('.uprank').forEach(function (element) {
            var rank = element.value;
            element.value = rank.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        });
    }
    uprank();




    // Только один отмеченный чекбокс с городом
    $('.group-city').on('click', '.input-checkbox', function () {
        var maps_center = JSON.parse('[' + $(this).data('maps-center') + ']');
        //myMap.panTo(maps_center);
        //$('.object-maps--link').attr({'data-coords-center': maps_center, 'data-link': maps_link});
        $('.object-maps--link').attr('data-coords-center', maps_center);
    });


    // склонение слов
    function declination(number, titles) {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    function decl_kv() {
        document.querySelectorAll('.decl-kv').forEach(function (el) {
            var count = el.textContent
            var title = declination(count, [' квартира', ' квартиры', ' квартир'])
            $(el).text(count + title);
        })
    }; decl_kv();
    function decl_kv2() {
        document.querySelectorAll('.decl-kv2').forEach(function (el) {
            var count = el.textContent
            var title = declination(count, [' квартиру', ' квартиры', ' квартир'])
            $(el).text(count + title);
        })
    }; decl_kv2();









    // SVG на плане
    // Показать квартиру при наведении
    function hover_popup(e) {
        $(e).on({
            mouseenter: function () {
                var i = $('#' + $(this).data('i'));
                i.show();
            },
            mouseleave: function () {
                var i = $('#' + $(this).data('i'));
                i.hide();
            },
            mousemove: function (e) {
                var i = $('#' + $(this).data('i'));
                i.css({ top: e.pageY, left: e.pageX });
            }
        });
    };

    // Показать квартиру при клике
    function click_popup(e) {
        $(e).on('click', function () {
            var i = $(this).data('i'),
                item = '#' + i;
            $('.catalog-hide').fadeIn(200);
            $(item).addClass('show');

            genplan();
        });
        $('.hint-close, .hint-close-2').on('click', function () {
            $('.hint').removeClass('show');
            setTimeout(function () {
                $('.catalog-hide').fadeOut(300);
            }, 300);
        });
    };


    hover_popup('.plan-svg .pin-item, .plan-svg .path-item, .flats-chess .chess-item');
    hover_svg('.plan-svg .pin-item, .plan-svg .path-item');


    if ($('body').innerWidth() + 17 > lg) {

        // Переходим по ссылке при клике на svg
        $('body').on('click', '.pin-item, .path-item, .chess-item', function () {
            var i = $(this).data('i'),
                item = $('#' + i);

            if (item.hasAttr('data-href')) {
                var href = item.data('href');
                window.location = href;
            }
        });


    } else {
        click_popup('.plan-svg .pin-item:not(".--comm"), .plan-svg .path-item:not(".--comm"), .flats-chess .chess-item');

    }


    // Показать попап или hint
    $(window).on("resize", function () {
        if ($('body').innerWidth() + 17 > lg) {
            $('.catalog-hide').show();
        } else {
            $('.catalog-hide').hide();
        }
    });


    // Центровка пина по метке
    function pin_center() {
        $('.plan-svg *[data-pin]').each(function () {
            var i = $(this).data('pin'),
                block = $(this).closest('.plan-svg'),
                offset = $(this).offset();

            var ileft = (offset.left - block.offset().left) / block.innerWidth() * 100;
            var itop = (offset.top - block.offset().top) / block.innerHeight() * 100;

            var styles = {
                left: ileft + '%',
                top: itop + '%'
            };

            $('.plan-svg .pin-item[data-i="' + i + '"]').css(styles);

        });
    };
    pin_center();


    // Наведение на svg и pin
    function hover_svg(e) {
        $(e).hover(function () {
            var i = $(this).data('i');
            $('*[data-i="' + i + '"]').addClass('hover');
        }, function () {
            var i = $(this).data('i');
            $('*[data-i="' + i + '"]').removeClass('hover');
        });
    };


    // Наличие объекта или бронь
    $('.plan-svg .path-item').each(function () {
        var i = $(this).data('i'),
            item = $('#' + i);

        if (item.hasClass('booked')) {
            $('*[data-i="' + i + '"]').addClass('--lock');
        }
        if (!item.hasAttr('data-href')) {
            $('*[data-i="' + i + '"]').addClass('--sold');
        }
    });


    $('body').on('click', '.plan-title', function () {
        pin_center();
    });

    // Переход на схему домов при клике на квартал
    function genplan() {
        $('.--level-1, .minimap').on('click', '.pin-item, .path-item', function () {
            var i = $(this).data('i'),
                title = $('.pin-item[data-i="' + i + '"]').data('title'),
                item = $('.--level-2[data-level="' + i + '"]');


            if (item.hasAttr('data-level')) {
                $('.--level-1, .--level-2').hide();
                item.fadeIn(300).css('display', 'flex');
                $('.plan-title').text(title);

                // Миникарта
                $('.minimap').fadeIn(300);
                $('.minimap .path-item').removeClass('active');
                $('.minimap .path-item[data-i="' + i + '"]').addClass('active');

                $('.btn-back').css('display', 'flex');
                pin_center();
            }
            return false;
        });

        $('.genplan').on('click', '.btn-back', function () {
            var title = $(this).data('title');
            $('.plan-title').text(title);
            $('.--level-1').fadeIn(300).css('display', 'flex');
            $('.--level-2, .btn-back').hide();
            $('.minimap').fadeOut(300);
        });

    };
    genplan();




    //https://fancyapps.com/panzoom/api/options/
    $('#myPanzoom').each(function () {
        const container = document.getElementById("myPanzoom");
        const fp = new Panzoom(container, {
            //click: "toggleCover",
            click: false,
            bounce: false,
            dblClick: "toggleCover",

            wheel: false, //"zoom" | "pan" | false,
        });
    });



    // Элемент прилипает https://xn----7sbbaqhlkm9ah9aiq.net/news-new/lipkaya-bokovaya-panel-sticky-sidebar-na-jquery.html
    $('.--sticky').each(function () {
        var stickySidebar = new StickySidebar('.--sticky', {
            topSpacing: 32,
            bottomSpacing: 32,
            containerSelector: false,
            resizeSensor: true,
            stickyClass: 'is-affixed',
            minWidth: xl
        });
    });






    // Переход по ссылке с сохранением параметров
    $('.filter-block').on('click', '.button2', function () {
        var search = window.location.search,
            href = $(this).attr('href');
        document.location.href = href + search;
        console.log(href + search);
        return false;
    });


    // Переход на форму другой страницы
    $('.header-filter').on('click', '.button', function () {
        var search = window.location.search,
            action = $(this).closest('form').attr('action');
        document.location.href = action + search;
    });


    // animate
    $(window).on('load scroll', function () {
        $('.animate').each(function () {
            if ($(this).offset().top < ($(document).scrollTop() + window.innerHeight * 0.9)) {
                $(this).addClass('now-u-can-see-me');
            }
        });
    });


    // Показать куки
    function view_cookie() {
        if (!Cookies.get('view_cookie') == true) {
            $('body').append('<div class="cookie"><div class="cookie__text">Мы используем <a href="">cookie</a>, чтобы вам было удобно.</div><div class="button --low cookie-btn">Ок</div></div>');
        }
        $('body').on('click', '.cookie-btn', function () {
            $('.cookie').fadeOut(300);
            Cookies.set('view_cookie', true, { expires: 30, path: '/' });
        });
    };
    view_cookie();


    // Круги при наведении
    $(".button, .btn-tab").on('mouseenter click', function (event) {
        $(".button .ripple").remove();
        $(this).append("<span class='ripple'></<span>");
        $(".ripple").css("left", event.pageX - $(this).offset().left);
        $(".ripple").css("top", event.pageY - $(this).offset().top);
        setTimeout(function () {
            $(".button .ripple").remove();
        }, 650);
    });


    // Иконка прокрутки в мобилке
    $(".skipit").on('mouseenter click', function (event) {
        $(this).fadeOut(300);
    });


    // scrollto
    $(".scrollto").click(function () {
        href = $(this).attr("href");
        destination = $(href).offset().top - 0;
        $("html:not(:animated),body:not(:animated)").animate({ scrollTop: destination }, 500);
        $('.header-top').removeClass('show');
        return false;
    });


    // msearch2 вывод результатов
    $(document).on('mse2_load', function (e, data) {
        decl_kv2();
        decl_kv();

        //more_filter();
        status_chess();

    });



    // Quiz
    $('.quiz').each(function () {
        var current = '#' + $(this).attr("id"),
            q_step = 0,
            time_start = Math.floor($(this).data('time') + '000'),
            time_exit = Math.floor($(this).data('time-exit') + '000'),
            step_total = $(current + ' .quiz-step').length;

        // появление через сколько то секунд
        if (time_start > 0) {
            setTimeout(function () {
                if (!Cookies.get('quiz_start') == true & !$(current).hasClass('show')) {
                    $(current).css('display', 'flex').hide().fadeIn(300).addClass('show');

                    // запишем куку чтобы не доставал
                    Cookies.set('quiz_start', true, { expires: 4, path: '/' });

                    ym(idmetrika, 'reachGoal', 'quiz_start');
                    return false;
                }
            }, time_start);
        }

        // появление при выходе с сайта
        if (time_exit > 0) {
            setTimeout(function () {
                $(document).mouseleave(function () {
                    if (!Cookies.get('quiz_exit_2') == true & !$(current).hasClass('show')) {
                        $(current).css('display', 'flex').hide().fadeIn(300).addClass('show');

                        // запишем куку чтобы не доставал
                        Cookies.set('quiz_exit_2', true, { expires: 4, path: '/' });

                        ym(idmetrika, 'reachGoal', 'quiz_start');
                        return false;
                    }
                });
            }, time_exit);
        }

        // полоска прогресса
        function ProgressOnlinePlayers(play, max) {
            let progress = Math.floor(play / Math.floor(max + 1) * 100);
            $(current + ' .quiz-steps__num').text(play + ' из ' + Math.floor(max + 1));
            $(current + ' .quiz-steps__progress .--bg').css('width', progress + '%');
        }


        // переключатель шагов вперед
        $(current + ' .quiz-btn-next').on('click', function () {
            $(current + ' .quiz-step,' + current + ' .quiz-start').removeClass('active').hide();

            q_step++;

            if (q_step > step_total) {
                $(current + ' .quiz-status').removeClass('active');
                $(current + ' .quiz-end').fadeIn(100);

                ym(idmetrika, 'reachGoal', 'quiz_end');
            } else {
                $(current + ' .quiz-status').addClass('active');
            }


            if (q_step == 1) {
                ym(idmetrika, 'reachGoal', 'quiz_quests');
            }


            $(current + ' #q' + q_step).addClass('active').fadeIn(100);

            ProgressOnlinePlayers(q_step, step_total);
            return false;
        });

        // переключатель шагов назад
        $(current + ' .quiz-btn-prev').on('click', function () {
            $(current + ' .quiz-step').removeClass('active').hide();

            q_step--;

            if (q_step < 1) {
                $(current + ' .quiz-start').addClass('active').fadeIn(100);
                $(current + ' .quiz-status').removeClass('active');
            }

            $(current + ' #q' + q_step).addClass('active').fadeIn(100);

            ProgressOnlinePlayers(q_step, step_total);
            return false;
        });


        // подсчет скидки
        $(current + ' .form-checkbox').on('click', function () {
            var q_price_dis = 0,
                q_area_dis = 0,
                q_remont_dis = 0,
                q_price_dis_max = $(current + ' form').data('discount-max');

            $(current + ' input:checked').each(function () {
                q_price_dis += isNaN(parseInt($(this).data('discount'))) ? 0 : parseInt($(this).data('discount'));
                q_area_dis += isNaN(parseInt($(this).data('area'))) ? 0 : parseInt($(this).data('area'));
                q_remont_dis += isNaN(parseInt($(this).data('remont'))) ? 0 : parseInt($(this).data('remont'));
            });

            if (q_price_dis >= q_price_dis_max) {
                var q_price_dis = q_price_dis_max;
            }

            var q_discont_pr = q_price_dis,
                q_discont_ar = parseInt(q_area_dis).toFixed(),
                q_discont_rem = parseInt(q_remont_dis).toFixed(),
                q_discont_all = parseInt(+q_discont_ar + +q_discont_pr).toFixed();

            //console.log('скидка мах ' + q_price_dis_max);
            //console.log('скидка ' + q_discont_pr);
            //console.log('лояльность ' + q_discont_ar);
            //console.log('плюс ремонт ' + q_discont_rem);
            //console.log('скидка вся ' + q_discont_all);

            if (q_discont_pr > 0 & q_discont_ar > 0) {

                $(current + ' .q-total-title').html('Ваша скидка на&nbsp;покупку квартиры<br> составляет <span>—&nbsp;' + q_discont_pr + '%</span> и&nbsp;<span>' + q_discont_ar + ' рублей</span> с&nbsp;каждого квадратного метра</span>');
                $(current + ' .quiz-in-discont').val('Скидка ' + q_discont_pr + '% и ' + q_discont_ar + 'р. с м²');

            } else if (q_discont_pr > 0) {

                $(current + ' .q-total-title').html('Ваша <span>скидка на&nbsp;покупку квартиры</span><br> составляет <span>—&nbsp;' + q_discont_pr + '%</span>');
                $(current + ' .quiz-in-discont').val('Скидка ' + q_discont_pr + '%');

            } else if (q_discont_ar > 0) {

                $(current + ' .q-total-title').html('Вы можете получить <span>скидку в&nbsp;' + q_discont_ar + '&nbsp;рублей</span> с&nbsp;каждого квадратного метра.');
                $(current + ' .quiz-in-discont').val('Скидка ' + q_discont_ar + 'р. с м²');

            } else {

                $(current + ' .q-total-title').html('Вы можете получить <span>скидку в&nbsp;500&nbsp;рублей</span> с&nbsp;каждого квадратного метра.');
                $(current + ' .quiz-in-discont').val('Нет скидки');

            }
        });

        rank();
    });



});
// end контейнера
